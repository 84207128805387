/* Terms.css */
.terms-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
}

.terms-content {
    max-width: 1000px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.terms-content h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
}

.terms-content h2 {
    font-size: 1.5em;
    margin-top: 20px;
    color: #555;
}

.terms-content p {
    font-size: 1em;
    line-height: 1.6;
    color: #666;
    margin-bottom: 20px;
}